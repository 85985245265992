.app {
  font-family: sans-serif;
  display: flex;
}

.image-viewer {
  position: relative;
  width: 675px;
  height: 875px;
}

.items {
  list-style-type: none;
  padding: 0;
  margin: 0;
  position: relative;
}

.item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 9;
}

.item img {
  opacity: 0;
}

.item.base {
  z-index: 1;
}

.item.overlay {
  z-index: 2;
}

.image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.image-wrapper img {
  display: block;
  width: 675px;
  height: 875px;
}

.view-finder {
  position: absolute;
  top: 0;
  left: 0;
  width: 95px;
  height: 125px;
}

.view-finder img {
  display: block;
  width: 100%;
  height: auto;
  border: 1px solid red;
}

.user-interface ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.user-interface li {
  display: block;
  margin-bottom: 10px;
}

.user-interface label {
  display: block;
}

.user-interface .annotations {
}

.user-interface .zoom {
  display: flex;
  align-items: flex-end;
  text-align: center;
}

.user-interface .layers {
}

.user-interface .reset {
}

.user-interface .expand {
}
